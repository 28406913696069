// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('@rails/activestorage').start()
require('channels')
import 'controllers'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
window.document.addEventListener('DOMContentLoaded', function () {
  document.getElementById('header-menu').onclick = function () {
    toggleMenu()
  }

  document.querySelector('.overlay').onclick = function () {
    toggleMenu()
  }
})

function toggleMenu() {
  document.body.classList.toggle('scroll-prevent')
  document.querySelector('.overlay').classList.toggle('overlay_open')
  document.getElementById('menu').classList.toggle('menu_open')
  var lines = document.getElementsByClassName('menu-button__line')
  lines[0].classList.toggle('menu-button__line_1_open')
  lines[1].classList.toggle('menu-button__line_2_open')
  lines[2].classList.toggle('menu-button__line_3_open')
}
