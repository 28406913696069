import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['parent', 'moreButton', 'closeButton']
  static values = {
    showPixel: { type: Number, default: 530 },
    buttonHeight: { type: Number, default: 50 },
  }

  areaHeight() {
    return this.showPixelValue + this.buttonHeightValue
  }

  initialize() {
    if (this.parentTarget.clientHeight && this.showPixelValue && this.parentTarget.clientHeight > this.areaHeight()) {
      this.parentTarget.style.height = `${this.areaHeight()}px`
      this.showMoreButton()
    }
  }

  onClickMore() {
    this.parentTarget.style.height = 'unset'
    this.removeMoreButton()
  }

  showMoreButton() {
    this.moreButtonTarget.style.display = 'flex'
  }

  removeMoreButton() {
    this.moreButtonTarget.style.display = 'none'
  }
}
